import {ar_AR} from './lang/ar';
import {fr_FR} from './lang/fr';
function OnConfig(
    $stateProvider, $locationProvider, $urlRouterProvider, $rootScopeProvider,
    $compileProvider, $httpProvider, $translateProvider, AppSettings
) {
    'ngInject';

    /**
     * translation
     */
    $translateProvider.translations('fr_FR', fr_FR);
    $translateProvider.translations('ar_AR', ar_AR , { rtl: true });

    //$translateProvider.preferredLanguage('fr_FR');
    $translateProvider.preferredLanguage(AppSettings.lang);
    //$translateProvider.useSanitizeValueStrategy('escaped');

    /**
     * fin translation
     */

    if (process.env.NODE_ENV === 'production') {
        $compileProvider.debugInfoEnabled(false);
    }
    $locationProvider.html5Mode({ enabled: true, requireBase: false });
    $httpProvider.defaults.useXDomain = true;
    $httpProvider.defaults.withCredentials = true;

    $stateProvider
        .state('app', {
            abstract: true,
            templateUrl: 'layouts/default.html',
            controller: 'globalController as app'
        })
        .state('app.dashboard', {
            url: '/',
            title: 'Dashboard',
            templateUrl: 'dashboard.html',
            bodyClass: '',
            controller: 'dashboardController as vm',
            resolve: {
                app: function () {
                    return false;
                }
            }
        })
        .state('app.dashboardcommande', {
            url: '/dashboardcommande',
            title: 'Dashboard Commande',
            templateUrl: 'dashboardCommande.html',
            bodyClass: '',
            controller: 'dashboardcommandeController as vm'
        })

        .state('app.achat', {
            url: '/achat',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'achatController as vm'
        })
        .state('app.achat.index', {
            url: '/',
            title: 'Achat',
            templateUrl: 'achat/index.html',
            bodyClass: '',
        })

        .state('app.achat.details', {
            url: '/details/{code}',
            title: 'Details Achat-retour',
            templateUrl: 'achat/details.html',
            bodyClass: '',
        })

        .state('app.inventaire', {
            url: '/inventaire',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'inventaireController as vm'
        })
        .state('app.inventaire.index', {
            url: '/',
            title: 'inventaire',
            templateUrl: 'inventaire/index.html',
            bodyClass: '',
        })

        .state('app.inventaire.details', {
            url: '/details/{code}',
            title: 'Details inventaire',
            templateUrl: 'inventaire/details.html',
            bodyClass: '',
        })


        .state('app.analyses', {
            url: '/analyses',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'analysesController as vm'
        })
        .state('app.analyses.index', {
            url: '/',
            title: 'Analyses',
            templateUrl: 'analyses/index.html',
            bodyClass: '',
        })

        .state('app.analyses.rapportdn', {
            url: '/rapport-dn',
            title: 'Rapport DN',
            templateUrl: 'analyses/dn.html',
            bodyClass: '',
        })
        .state('app.mapvision', {
            url: '/mapvision',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'mapVisionController as vm'
        })
        .state('app.mapvision.index', {
            url: '/',
            title: 'Map Vision',
            templateUrl: 'mapVision/index.html',
            bodyClass: '',
        })

        .state('app.users', {
            url: '/users',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'userController as vm'
        })
        .state('app.users.index', {
            url: '/',
            title: 'Utilisateur',
            templateUrl: 'users/index.html',
            bodyClass: '',
        })
        .state('app.users.listUsers', {
            url: '/list?user_code',
            title: 'List Users',
            templateUrl: 'users/new_list_users.html',
            bodyClass: '',
        })
        .state('app.users.affectations', {
            url: '/affectations',
            title: 'affectations',
            templateUrl: 'users/affectations.html',
            bodyClass: '',
        })
        .state('app.users.affectationscm', {
            url: '/affectationsCommercialCamion',
            title: 'affectations Commercial Camion',
            templateUrl: 'users/affectationCommercialCamion.html',
            bodyClass: '',
        })

        .state('app.users.pays', {
            url: '/pays',
            title: 'Pays',
            templateUrl: 'users/pays.html',
            bodyClass: '',
        })
        .state('app.kpi', {
            url: '/kpi',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'kpiController as vm'
        })
        .state('app.kpi.index', {
            url: '/',
            title: 'kpi',
            templateUrl: 'kpi/index.html',
            bodyClass: '',
        })

        .state('app.smartArea', {
            url: '/smartArea',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'smartAreaController as vm'
        })
        .state('app.smartArea.index', {
            url: '/',
            title: 'smartArea',
            templateUrl: 'smartArea/index.html',
            bodyClass: '',
        })



        .state('app.rapports', {
            url: '/rapports',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'rapportController as vm'
        })
        .state('app.rapports.index', {
            url: '/',
            title: 'rapports',
            templateUrl: 'rapports/index.html',
            bodyClass: '',
        })
        .state('app.rapports.commandes', {
            url: '/commandes',
            title: 'rapports',
            templateUrl: 'rapports/commande.html',
            bodyClass: '',
        })
        .state('app.rapports.recaptour', {
            url: '/recapjour',
            title: 'recap Journalier',
            templateUrl: 'rapports/recapjour.html',
            bodyClass: '',
        })
        .state('app.rapports.vente', {
            url: '/vente',
            title: 'Rapport vente',
            templateUrl: 'rapports/vente.html',
            bodyClass: '',
        })
        .state('app.rapports.ventecommercials', {
            url: '/ventecommercials',
            title: 'Rapport vente',
            templateUrl: 'rapports/ventecommercials.html',
            bodyClass: '',
        })
        .state('app.rapports.dngammevendeur', {
            url: '/dngammevendeur',
            title: 'Rapport Dn Gamme Vendeur',
            templateUrl: 'rapports/dngammevendeur.html',
            bodyClass: '',
        })
        .state('app.rapports.ventesoufamilles', {
            url: '/ventesoufamilles',
            title: 'Rapport vente',
            templateUrl: 'rapports/ventesoufamilles.html',
            bodyClass: '',
        })
        .state('app.clients', {
            url: '/clients',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'clientController as vm'
        })
        .state('app.clients.index', {
            url: '/',
            title: 'Client',
            templateUrl: 'clients/index.html',
            bodyClass: '',
        })
        .state('app.clients.liste', {
            url: '/liste',
            title: 'Clients',
            templateUrl: 'clients/new_list_clients.html',
            bodyClass: '',
        })
        .state('app.clients.ajouter', {
            url: '/ajouter',
            title: 'Ajouter client',
            templateUrl: 'clients/ajouter.html',
            bodyClass: '',
        })
        .state('app.clients.parametre', {
            url: '/parametre',
            title: 'Parametre client',
            templateUrl: 'clients/parametre.html',
            bodyClass: '',
        })
        .state('app.clients.encoursmarque', {
            url: '/encoursmarque',
            title: 'encours marque client',
            templateUrl: 'clients/encoursMarque.html',
            bodyClass: '',
        })
        .state('app.clients.update-routings', {
            url: '/update-routings',
            title: 'update-routings',
            templateUrl: 'clients/update-routings.html',
            bodyClass: '',
        })
        .state('app.clients.details', {
            url: '/{id}',
            title: 'Détails client',
            templateUrl: 'clients/details.html',
            bodyClass: '',
        })
        .state('app.clients.mouvements', {
            url: '/mouvements/{id}',
            title: 'mouvements client',
            templateUrl: 'clients/mouvements_client.html',
            bodyClass: '',
        })
        .state('app.clients.fidelites', {
            url: '/fidelite/{id}',
            title: 'fidelites',
            templateUrl: 'clients/fidelites.html',
            bodyClass: '',
        })


        .state('app.users.add', {
            url: '/ajouter',
            title: 'Ajouter',
            templateUrl: 'users/add.html',
            bodyClass: '',
        })
        .state('app.users.edit', {
            url: '/edit/{id}',
            title: 'Modifier',
            templateUrl: 'users/edit.html',
            bodyClass: '',
        })
        .state('app.users.info', {
            url: '/info',
            title: 'Info',
            templateUrl: 'users/info.html',
            bodyClass: '',
        })
        .state('app.users.chargement', {
            url: '/chargement',
            title: 'Chargement',
            templateUrl: 'users/chargement.html',
            bodyClass: '',
        })
        .state('app.routings', {
            url: '/routings',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'routingsController as vm'
        })
        .state('app.routings.index', {
            url: '/',
            title: 'routings',
            templateUrl: 'routings/index.html',
            bodyClass: '',
        })
        .state('app.routings.suivi', {
            url: '/suivi',
            title: 'routings',
            templateUrl: 'routings/suivi.html',
            bodyClass: '',
        })
        .state('app.routings.suivicommande', {
            url: '/suivicommande',
            title: 'routings',
            templateUrl: 'routings/suiviCommande.html',
            bodyClass: '',
        })
        .state('app.routings.tournee', {
            url: '/tournee',
            title: 'tournee',
            templateUrl: 'routings/tournee.html',
            bodyClass: '',
        })
        .state('app.routings.details', {
            url: '/tournee/{route_code}/{tournee_code}/{depot_code}/{code_jour}',
            title: 'tourneeClient',
            templateUrl: 'routings/tourneeClient.html',
            bodyClass: '',
        })
        .state('app.rapportsvisite', {
            url: '/rapportsvisite',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'rapportvisiteController as vm'
        })
        .state('app.rapportsvisite.index', {
            url: '/',
            title: 'rapportsvisite',
            templateUrl: 'rapportsvisite/index.html',
            bodyClass: '',
        })
        .state('app.rapportsvisite.details', {
            url: '/details',
            title: 'Details Rapport Visite',
            templateUrl: 'rapportsvisite/details.html',
            bodyClass: '',
        })
        .state('app.gammes', {
            url: '/gammes',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'gammeController as vm'
        })
        .state('app.gammes.index', {
            url: '/',
            title: 'gammes',
            templateUrl: 'gammes/index.html',
            bodyClass: '',
        })
        .state('app.devices', {
            url: '/devices',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'deviceController as vm'
        })
        .state('app.devices.index', {
            url: '/',
            title: 'devices',
            templateUrl: 'devices/index.html',
            bodyClass: '',
        })
        .state('app.groupes', {
            url: '/groupes',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'groupeController as vm'
        })
        .state('app.groupes.index', {
            url: '/',
            title: 'groupes',
            templateUrl: 'groupes/index.html',
            bodyClass: '',
        })
        .state('app.groupes.affectations', {
            url: '/affectations',
            title: 'groupes affectations',
            templateUrl: 'groupes/affectations.html',
            bodyClass: '',
        })
        .state('app.tarifications', {
            url: '/tarifications',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'tarificationController as vm'
        })
        .state('app.tarifications.index', {
            url: '/',
            title: 'tarifications',
            templateUrl: 'tarifications/index.html',
            bodyClass: '',
        })
        .state('app.marques', {
            url: '/marques',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'marqueController as vm'
        })
        .state('app.marques.index', {
            url: '/',
            title: 'marques',
            templateUrl: 'marques/index.html',
            bodyClass: '',
        })
        .state('app.marques.remise', {
            url: '/remise',
            title: 'Remise',
            templateUrl: 'marques/remise.html',
            bodyClass: '',
        })
        .state('app.zones', {
            url: '/zones',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'zoneController as vm'
        })
        .state('app.zones.index', {
            url: '/',
            title: 'zones',
            templateUrl: 'zones/index.html',
            bodyClass: '',
        })
        .state('app.depots', {
            url: '/depots',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'depot as vm'
        })
        .state('app.depots.index', {
            url: '/',
            title: 'depots',
            templateUrl: 'depots/index.html',
            bodyClass: '',
        })
        .state('app.depots.edit', {
            url: '/edit/{id}',
            title: 'Modifier depots',
            templateUrl: 'depots/edit.html',
            bodyClass: '',
        })
        // .state('app.depots.kilometrages', {
        //     url: '/kilometrages',
        //     title: 'kilometrages',
        //     templateUrl: 'depots/kilometrages.html',
        //     bodyClass: '',
        // })

        .state('app.kilometrages', {
            url: '/kilometrages',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'kilometrageController as vm'
        })
        .state('app.kilometrages.index', {
            url: '/',
            title: 'kilometrages',
            templateUrl: 'kilometrages/index.html',
            bodyClass: '',
        })

        .state('app.equipements', {
            url: '/equipements',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'equipement as vm'
        })
        .state('app.equipements.index', {
            url: '/',
            title: 'equipements',
            templateUrl: 'equipements/index.html',
            bodyClass: '',
        })

        .state('app.equipementsclients', {
            url: '/equipementsclients',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'equipementsclients as vm'
        })
        .state('app.equipementsclients.index', {
            url: '/',
            title: 'equipementsclients',
            templateUrl: 'equipementsclients/index.html',
            bodyClass: '',
        })
        .state('app.traces', {
            url: '/traces',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'traces as vm'
        })
        .state('app.traces.index', {
            url: '/',
            title: 'traces',
            templateUrl: 'traces/index.html',
            bodyClass: '',
        })
        .state('app.secteurs', {
            url: '/secteurs',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'secteurController as vm'
        })
        .state('app.secteurs.index', {
            url: '/',
            title: 'secteurs',
            templateUrl: 'secteurs/index.html',
            bodyClass: '',
        })
        .state('app.regions', {
            url: '/regions',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'regionController as vm'
        })
        .state('app.regions.index', {
            url: '/',
            title: 'regions',
            templateUrl: 'regions/index.html',
            bodyClass: '',
        })
        .state('app.fournisseurs', {
            url: '/fournisseurs',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'fournisseurController as vm'
        })
        .state('app.fournisseurs.index', {
            url: '/',
            title: 'fournisseurs',
            templateUrl: 'fournisseurs/index.html',
            bodyClass: '',
        })
        .state('app.activites', {
            url: '/activites',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'activiteController as vm'
        })
        .state('app.activites.index', {
            url: '/',
            title: 'activites',
            templateUrl: 'activites/index.html',
            bodyClass: '',
        })

        .state('app.tickets', {
            url: '/tickets',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'ticketsController as vm'
        })
        .state('app.tickets.index', {
            url: '/',
            title: 'tickets',
            templateUrl: 'tickets/index.html',
            bodyClass: '',
        })
        .state('app.tickets.cloture', {
            url: '/',
            title: 'tickets',
            templateUrl: 'tickets/tickets.html',
            bodyClass: '',
        })

        .state('app.charges', {
            url: '/charges',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'chargeController as vm'
        })
        .state('app.charges.index', {
            url: '/',
            title: 'charges',
            templateUrl: 'charges/charges.html',
            bodyClass: '',
        })
        .state('app.charges.analyses', {
            url: '/analyses',
            title: 'analyses',
            templateUrl: 'charges/analyseCharge.html',
            bodyClass: '',
        })
        .state('app.charges.types', {
            url: '/types',
            title: 'types',
            templateUrl: 'charges/typeCharges.html',
            bodyClass: '',
        })
        .state('app.charges.centre_cout', {
            url: '/centre_cout',
            title: 'centre_cout',
            templateUrl: 'charges/centreCout.html',
            bodyClass: '',
        })
        .state('app.delegations', {
            url: '/delegations',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'delegationController as vm'
        })
        .state('app.delegations.index', {
            url: '/',
            title: 'delegations',
            templateUrl: 'delegations/index.html',
            bodyClass: '',
        })
        .state('app.gouvernorats', {
            url: '/gouvernorats',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'gouvernoratController as vm'
        })
        .state('app.gouvernorats.index', {
            url: '/',
            title: 'gouvernorats',
            templateUrl: 'gouvernorats/index.html',
            bodyClass: '',
        })
        .state('app.localites', {
            url: '/localites',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'localiteController as vm'
        })
        .state('app.localites.index', {
            url: '/',
            title: 'localites',
            templateUrl: 'localites/index.html',
            bodyClass: '',
        })
        .state('app.familles', {
            url: '/familles',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'familleController as vm'
        })
        .state('app.familles.index', {
            url: '/',
            title: 'familles',
            templateUrl: 'familles/index.html',
            bodyClass: '',
        })
        .state('app.sousfamilles', {
            url: '/sousfamilles',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'sousfamilleController as vm'
        })
        .state('app.sousfamilles.index', {
            url: '/',
            title: 'sousfamilles',
            templateUrl: 'sousfamilles/index.html',
            bodyClass: '',
        })
        .state('app.produits', {
            url: '/produits',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'produitsController as vm'
        })
        .state('app.produits.index', {
            url: '/',
            title: 'produits',
            templateUrl: 'produits/index.html',
            bodyClass: '',
        })
        .state('app.produits.affectationclients', {
            url: '/affectationclients/{indice}',
            title: 'affectationClients',
            templateUrl: 'produits/affectationClients.html',
            bodyClass: '',
        })
        .state('app.produits.affectationclientsgratuite', {
            url: '/affectationclientsgratuite',
            title: 'affectationClientsGratuite',
            templateUrl: 'produits/affectationClientsGratuite.html',
            bodyClass: '',
        })
        .state('app.produits.affectationproduits', {
            url: '/affectationProduits',
            title: 'affectationProduits',
            templateUrl: 'produits/affectationProduits.html',
            bodyClass: '',
        })
        .state('app.produits.add', {
            url: '/ajouter',
            title: 'ajouter',
            templateUrl: 'produits/add.html',
            bodyClass: '',
        })
        .state('app.produits.pack', {
            url: '/pack',
            title: 'pack',
            templateUrl: 'produits/pack.html',
            bodyClass: '',
        })
        .state('app.produits.detailspack', {
            url: '/pack/details/{code}',
            title: 'Details Pack',
            templateUrl: 'produits/detailspack.html',
            bodyClass: '',
        })


        .state('app.produits.remises', {
            url: '/remise',
            title: 'remise',
            templateUrl: 'produits/remise.html',
            bodyClass: '',
        })
        .state('app.produits.gratuites', {
            url: '/gratuites',
            title: 'gratuites',
            templateUrl: 'produits/gratuites.html',
            bodyClass: '',
        })
        .state('app.produits.gratuitesdetail', {
            url: '/gratuites/details/{code}',
            title: 'DetailsGratuite',
            templateUrl: 'produits/conditionGratuites.html',
            bodyClass: '',
        })

        .state('app.produits.edit', {
            url: '/{id}',
            title: 'edit',
            templateUrl: 'produits/edit.html',
            bodyClass: '',
        })
        .state('app.societes', {
            url: '/societes',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'societeController as vm'
        })
        .state('app.societes.index', {
            url: '/',
            title: 'societes',
            templateUrl: 'societes/index.html',
            bodyClass: '',
        })
        .state('app.societes.add', {
            url: '/ajouter',
            title: 'ajouter',
            templateUrl: 'societes/add.html',
            bodyClass: '',
        })
        .state('app.societes.edit', {
            url: '/{id}',
            title: 'edit',
            templateUrl: 'societes/edit.html',
            bodyClass: '',
        })
        .state('app.distributeurs', {
            url: '/distributeurs',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'distributeurController as vm'
        })
        .state('app.distributeurs.index', {
            url: '/',
            title: 'distributeurs',
            templateUrl: 'distributeurs/index.html',
            bodyClass: '',
        })
        .state('app.distributeurs.add', {
            url: '/ajouter',
            title: 'ajouter',
            templateUrl: 'distributeurs/add.html',
            bodyClass: '',
        })
        .state('app.distributeurs.edit', {
            url: '/{id}',
            title: 'edit',
            templateUrl: 'distributeurs/edit.html',
            bodyClass: '',
        })
        .state('app.commandes', {
            url: '/commandes',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'commandesController as vm'
        })
        .state('app.commandes.index', {
            url: '/{user_code}/{date_debut}/{date_fin}',
            title: 'commandes',
            templateUrl: 'commandes/index.html',
            bodyClass: '',
        })
        .state('app.commandes.details', {
            url: '/details/{id}',
            title: 'Détails commande',
            templateUrl: 'commandes/details.html',
            bodyClass: '',
        })
        .state('app.devis', {
            url: '/devis',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'devisController as vm'
        })
        .state('app.devis.index', {
            url: '/{user_code}',
            title: 'devis',
            templateUrl: 'devis/index.html',
            bodyClass: '',
        })
        .state('app.devis.details', {
            url: '/details/{id}',
            title: 'Détails devis',
            templateUrl: 'devis/details.html',
            bodyClass: '',
        })
        .state('app.facture', {
            url: '/facture',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'factureController as vm'
        })
        .state('app.facture.index', {
            url: '/{user_code}',
            title: 'facture',
            templateUrl: 'facture/index.html',
            bodyClass: '',
        })
        .state('app.facture.details', {
            url: '/details/{id}',
            title: 'Détails facture',
            templateUrl: 'facture/details.html',
            bodyClass: '',
        })
        .state('app.notes', {
            url: '/notes',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'noteController as vm'
        })
        .state('app.notes.index', {
            url: '/',
            title: 'Notes',
            templateUrl: 'notes/index.html',
            bodyClass: '',
        })

        .state('app.notes.details', {
            url: '/{id}',
            title: 'Note',
            templateUrl: 'notes/details.html',
            bodyClass: '',
        })
        .state('app.carburants', {
            url: '/carburants',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'carburantController as vm'
        })
        .state('app.carburants.index', {
            url: '/',
            title: 'carburants',
            templateUrl: 'carburants/index.html',
            bodyClass: '',
        })
        .state('app.carburants.consommation', {
            url: '/consommation',
            title: 'Consommation',
            templateUrl: 'carburants/details.html',
            bodyClass: '',
        })
        .state('app.caisses', {
            url: '/caisses',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'caisseController as vm'
        })
        .state('app.caisses.index', {
            url: '/',
            title: 'caisses',
            templateUrl: 'caisses/index.html',
            bodyClass: '',
        })
        .state('app.depences', {
            url: '/depences',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'depencesController as vm'
        })
        .state('app.depences.index', {
            url: '/',
            title: 'depences',
            templateUrl: 'depences/index.html',
            bodyClass: '',
        })
        .state('app.banques', {
            url: '/banques',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'banqueController as vm'
        })
        .state('app.banques.index', {
            url: '/',
            title: 'banques',
            templateUrl: 'banques/index.html',
            bodyClass: '',
        })
        .state('app.bl', {
            url: '/bl',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'blController as vm'
        })
        .state('app.bl.details', {
            url: '/details/{id}',
            title: 'Détails bl',
            templateUrl: 'bl/details.html',
            bodyClass: '',
        })
        .state('app.bl.index', {
            url: '/{user_code}/{date_debut}/{date_fin}',
            title: 'bonslivraison',
            templateUrl: 'bl/index.html',
            bodyClass: '',
        })
        .state('app.cloturebls', {
            url: '/cloturebls',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'clotureBlsController as vm'
        })
        .state('app.cloturebls.index', {
            url: '/',
            title: 'bls',
            templateUrl: 'cloturebls/index.html',
            bodyClass: '',
        })
        .state('app.blf', {
            url: '/blf',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'blfController as vm'
        })
        .state('app.blf.index', {
            url: '/',
            title: 'Bl Facture',
            templateUrl: 'bl/blf.html',
            bodyClass: '',
        })
        .state('app.deleted_entete', {
            url: '/deleted_entete',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'blController as vm'
        })
        .state('app.deleted_entete.index', {
            url: '/',
            title: 'Entete supprimées',
            templateUrl: 'bl/deleted_entete.html',
            bodyClass: '',
        })
        .state('app.cadeaux', {
            url: '/cadeaux',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'blController as vm'
        })
        .state('app.cadeaux.index', {
            url: '/',
            title: 'Cadeaux',
            templateUrl: 'bl/cadeaux.html',
            bodyClass: '',
        })
        .state('app.plandecharges', {
            url: '/plandecharges',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'planDeChargeController as vm'
        })
        .state('app.plandecharges.details', {
            url: '/details/{id}',
            title: 'Détails visite',
            templateUrl: 'plan_de_charge/details.html',
            bodyClass: '',
        })
        .state('app.plandecharges.add', {
            url: '/add',
            title: 'Ajouter visite',
            templateUrl: 'plan_de_charge/add_plan_charge.html',
            bodyClass: '',
        })
        .state('app.plandecharges.visite', {
            url: '/visites_plan',
            title: 'Visites',
            templateUrl: 'plan_de_charge/visites.html',
            bodyClass: '',
        })
        .state('app.plandecharges.index', {
            url: '/{user_code}/{date_debut}/{date_fin}',
            title: 'plan de charges',
            templateUrl: 'plan_de_charge/index.html',
            bodyClass: '',
        })
        .state('app.mouvements', {
            url: '/mouvements',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'mouvementsController as vm'
        })
        .state('app.mouvements.index', {
            url: '/',
            title: 'mouvements',
            templateUrl: 'mouvements/index.html',
            bodyClass: '',
        })
       
        .state('app.objectifs', {
            url: '/objectifs',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'objectifController as vm'
        })
        .state('app.objectifs.index', {
            url: '/',
            title: 'objectifs',
            templateUrl: 'objectifs/index.html',
            bodyClass: '',
        })
        .state('app.objectifs.commandes', {
            url: '/commandes',
            title: 'commandes',
            templateUrl: 'objectifs/commande.html',
            bodyClass: '',
        })
        .state('app.fidelites', {
            url: '/fidelites',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'fideliteController as vm'
        })
        .state('app.fidelites.index', {
            url: '/',
            title: 'fidelites',
            templateUrl: 'fidelites/index.html',
            bodyClass: '',
        })
       

        .state('app.clients.historiques', {
            url: '/historiques/{code}',
            title: 'historique client',
            templateUrl: 'clients/historique.html',
            bodyClass: '',
        })
        .state('app.clients.images', {
            url: '/images/{code}',
            title: 'images client',
            templateUrl: 'clients/image.html',
            bodyClass: '',
        })
        .state('app.fidelites.fidelitesdetail', {
            url: '/fidelites/details/{code}',
            title: 'Details Fidelite',
            templateUrl: 'fidelites/conditionfidelites.html',
            bodyClass: '',
        })
        .state('app.fidelites.affectationclientsfidelites', {
            url: '/affectationclientsfidelites',
            title: 'affectationClientsFidelites',
            templateUrl: 'fidelites/affectationClientsFidelites.html',
            bodyClass: '',
        })
        .state('app.chargements', {
            url: '/chargements',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'chargementController as vm'
        })
        .state('app.chargements.index', {
            url: '/',
            title: 'chargements',
            templateUrl: 'chargements/index.html',
            bodyClass: '',
        })
        .state('app.chargements.transfert', {
            url: '/transfert',
            title: 'Transfert',
            templateUrl: 'chargements/transfert.html',
            bodyClass: '',
        })
        .state('app.chargements.stat-chargements', {
            url: '/stat-chargements',
            title: 'chargements',
            templateUrl: 'chargements/stat-chargements.html',
            bodyClass: '',
        })
        .state('app.chargements.stat-vente', {
            url: '/stat-vente',
            title: 'chargements',
            templateUrl: 'chargements/stat-vente.html',
            bodyClass: '',
        })
        .state('app.chargements.stat_chargements_by_caisse', {
            url: '/stat_chargements/{caisse_code}/{commercial_code}',
            title: 'chargements',
            templateUrl: 'chargements/stat-chargements.html',
            bodyClass: '',
        })
        .state('app.chargements.liste', {
            url: '/liste',
            title: 'chargementsliste',
            templateUrl: 'chargements/liste.html',
            bodyClass: '',
        })
        .state('app.chargements.retour', {
            url: '/retour',
            title: 'liste retour',
            templateUrl: 'chargements/retour.html',
            bodyClass: '',
        })
        .state('app.chargements.demande', {
            url: '/demande',
            title: 'Demande chargements',
            templateUrl: 'chargements/demande.html',
            bodyClass: '',
        })
        .state('app.chargements.update', {
            url: '/update/{numero}',
            title: 'Modifier Chargement',
            templateUrl: 'chargements/update.html',
            bodyClass: '',
        })
         .state('app.chargements.detailsretour', {
            url: '/detailsretour/{numero}',
            title: 'Modifier Retour',
            templateUrl: 'chargements/detailsretour.html',
            bodyClass: '',
        })
        .state('app.chargements.details', {
            url: '/details/{numero}',
            title: 'Details Demande',
            templateUrl: 'chargements/details.html',
            bodyClass: '',
        })
        .state('app.chargements.type', {
            url: '/chargement_type',
            title: 'chargement type',
            templateUrl: 'chargements/chargement_type.html',
            bodyClass: '',
        })
        .state('app.chargements.consultation-commande', {
            url: '/consultation_commande',
            title: 'consultation_commande',
            templateUrl: 'chargements/consultation_commande.html',
            bodyClass: '',
        })

        .state('app.quota', {
            url: '/quota',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'quotaController as vm'
        })
        .state('app.quota.index', {
            url: '/',
            title: 'quota',
            templateUrl: 'quota/index.html',
            bodyClass: '',
        })



        .state('app.modules', {
            url: '/modules',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'modulesController as vm'
        })
        .state('app.modules.index', {
            url: '/',
            title: 'modules',
            templateUrl: 'modules/index.html',
            bodyClass: '',
        })

        .state('app.synchronisation', {
            url: '/synchronisation',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'synchronisationController as vm'
        })
        .state('app.synchronisation.index', {
            url: '/',
            title: 'synchronisation',
            templateUrl: 'affectation_synchronisation/index.html',
            bodyClass: '',
        })





        .state('app.recouvrements', {
            url: '/recouvrements',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'recouvrementController as vm'
        })
        .state('app.recouvrements.index', {
            url: '/',
            title: 'recouvrements',
            templateUrl: 'recouvrement/index.html',
            bodyClass: '',
        })
        .state('app.recouvrements.by_user', {
            url: '/{user_code}',
            title: 'recouvrements',
            templateUrl: 'recouvrement/index.html',
            bodyClass: '',
        })
        .state('app.recouvrements.details', {
            url: '/details/{id}',
            title: 'Details Recouvrement',
            templateUrl: 'recouvrement/details.html',
            bodyClass: '',
        })
        .state('app.stocks', {
            url: '/stocks',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'stockController as vm'
        })
        .state('app.stocks.index', {
            url: '/',
            title: 'stocks',
            templateUrl: 'stocks/index.html',
            bodyClass: '',
        })

        .state('app.stockDepot', {
            url: '/stockDepot',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'stock_depotController as vm'
        })
        .state('app.stockDepot.index', {
            url: '/',
            title: 'stockDepot',
            templateUrl: 'stock_depot/index.html',
            bodyClass: '',
        })
        .state('app.stockDepot.historique', {
            url: '/historique',
            title: 'stockDepot',
            templateUrl: 'stock_depot/historique.html',
            bodyClass: '',
        })

        .state('app.parametrages', {
            url: '/parametrages',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'parametrageController as vm'
        })
        .state('app.parametrages.index', {
            url: '/',
            title: 'parametrages',
            templateUrl: 'parametrages/index.html',
            bodyClass: '',
        })
        .state('app.parametrages.echeances', {
            url: '/echeances',
            title: 'Echeances',
            templateUrl: 'parametrages/echeances.html',
            bodyClass: '',
        })
        .state('app.parametrages.credits', {
            url: '/credits',
            title: 'Credit',
            templateUrl: 'parametrages/credits.html',
            bodyClass: '',
        })
        .state('app.parametrages.factures', {
            url: '/factures',
            title: 'Factures',
            templateUrl: 'parametrages/factures.html',
            bodyClass: '',
        })
        .state('app.parametrages.nvclients', {
            url: '/nvclients',
            title: 'nvclients',
            templateUrl: 'parametrages/nvclients.html',
            bodyClass: '',
        })
        .state('app.parametrages.commandes', {
            url: '/commandes',
            title: 'commandes',
            templateUrl: 'parametrages/commandes.html',
            bodyClass: '',
        })
        .state('app.parametrages.demandeAvoir', {
            url: '/demandeAvoir',
            title: 'demandeAvoir',
            templateUrl: 'parametrages/demandeAvoir.html',
            bodyClass: '',
        })
        .state('app.parametrages.plafondclients', {
            url: '/plafondclients',
            title: 'plafondclients',
            templateUrl: 'parametrages/plafondclients.html',
            bodyClass: '',
        })
        .state('app.parametrages.creditencours', {
            url: '/creditencours/{user_code}',
            title: 'creditencours',
            templateUrl: 'parametrages/creditsEncours.html',
            bodyClass: '',
        })
        .state('app.parametrages.visiteHorsClient', {
            url: '/visiteHorsClient',
            title: 'visiteHorsClient',
            templateUrl: 'parametrages/visiteHorsClient.html',
            bodyClass: '',
        })
        .state('app.parametrages.kpicommercial', {
            url: '/kpicommercial',
            title: 'kpicommercial',
            templateUrl: 'parametrages/kpicommercial.html',
            bodyClass: '',
        })
        .state('app.parametrages.details', {
            url: '/details/{id}',
            title: 'Détails commande',
            templateUrl: 'parametrages/detailscommande.html',
            bodyClass: '',
        })
        .state('app.parametrages.detailsAvoir', {
            url: '/detailsAvoir/{id}',
            title: 'Détails Avoir',
            templateUrl: 'parametrages/detailsAvoir.html',
            bodyClass: '',
        })
        .state('app.todos', {
            url: '/todos',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'todoController as vm'
        })
        .state('app.todos.index', {
            url: '/',
            title: 'todos',
            templateUrl: 'todos/index.html',
            bodyClass: '',
        })
        .state('app.todos.details', {
            url: '/{todo_code}',
            title: 'todo - details',
            templateUrl: 'todos/details.html',
            bodyClass: '',
        })
        .state('app.404', {
            url: '/404',
            abstract: true,
            template: '<div ui-view></div>',
            controller: function () {

            }
        })
        .state('app.404.index', {
            url: '/',
            title: '404',
            templateUrl: 'errors/404.html',
            bodyClass: '',
        })

        .state('app.authorization', {
            url: '/authorization',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'authorizationController as vm'
        })
        .state('app.authorization.index', {
            url: '/{role_code}',
            title: 'authorization',
            templateUrl: 'authorization/index.html',
            bodyClass: '',
        })

        .state('app.integrationerp', {
            url: '/integrationerp',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'integrationerpController as vm'
        })
        .state('app.integrationerp.index', {
            url: '/',
            title: 'integration ERP',
            templateUrl: 'integrationerp/index.html',
            bodyClass: ''
        })

        .state('app.roles', {
            url: '/roles',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'rolesController as vm'
        })
        .state('app.roles.index', {
            url: '/',
            title: 'roles utilisateurs',
            templateUrl: 'roles/index.html',
            bodyClass: ''
        })


        .state('app.commissions', {
            url: '/commissions',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'commisionsController as vm'
        })
        .state('app.commissions.meilleur_vendeur', {
            url: '/commissions_meilleur_vendeur',
            title: 'meilleur_vendeur',
            templateUrl: 'commissions/commission_meilleur_vendeur.html',
            bodyClass: ''
        })

        .state('app.commissions.placement', {
            url: '/commissions_placement',
            title: 'placement',
            templateUrl: 'commissions/commission_placement.html',
            bodyClass: ''
        })

        .state('app.commissions.produits', {
            url: '/commissions_produits',
            title: 'produits',
            templateUrl: 'commissions/commission_produits.html',
            bodyClass: ''
        })

        .state('app.commissions.visite', {
            url: '/commissions_visite',
            title: 'visite',
            templateUrl: 'commissions/commission_visite.html',
            bodyClass: ''
        })

        .state('app.commissions.vente', {
            url: '/commissions_vente',
            title: 'vente',
            templateUrl: 'commissions/commission_vente.html',
            bodyClass: ''
        })

        .state('app.consignes', {
            url: '/consignes',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'consignesController as vm'
        })
        .state('app.consignes.index', {
            url: '/',
            title: 'Consignes',
            templateUrl: 'consignes/index.html',
            bodyClass: ''
        })

        .state('app.gestionCommandes', {
            url: '/gestionCommandes',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'gestion_cmdController as vm'
        })
        .state('app.gestionCommandes.index', {
            url: '/',
            title: 'gestion Commandes',
            templateUrl: 'gestion_cmd/index.html',
            bodyClass: ''
        })

        .state('app.powerBi', {
            url: '/powerBi',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'biController as vm'
        })
        .state('app.powerBi.index', {
            url: '/',
            title: 'Power Bi',
            templateUrl: 'bi/index.html',
            bodyClass: ''
        })

        .state('app.realisation', {
            url: '/realisation',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'realisationController as vm'
        })
        .state('app.realisation.index', {
            url: '/',
            title: 'Réalisation',
            templateUrl: 'realisation/index.html',
            bodyClass: ''
        })


        .state('app.mouvementclients', {
            url: '/mouvementclients',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'mouvement_clientsController as vm'
        })
        .state('app.mouvementclients.index', {
            url: '/',
            title: 'Mouvement Clients',
            templateUrl: 'mouvement_clients/index.html',
            bodyClass: ''
        })


        .state('app.releveprix', {
            url: '/releveprix',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'releveprixController as vm'
        })
        .state('app.releveprix.index', {
            url: '/',
            title: 'Releve Prix',
            templateUrl: 'releveprix/index.html',
            bodyClass: ''
        })


        .state('app.conventions', {
            url: '/conventions',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'conventionsController as vm'
        })
        .state('app.conventions.index', {
            url: '/',
            title: 'Conventions',
            templateUrl: 'conventions/index.html',
            bodyClass: ''
        })

        .state('app.assortiments', {
            url: '/assortiments',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'assortimentsController as vm'
        })
        .state('app.assortiments.index', {
            url: '/',
            title: 'assortiments',
            templateUrl: 'assortiments/index.html',
            bodyClass: ''
        })

        .state('app.visites', {
            url: '/visites',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'visitesController as vm'
        })
        .state('app.visites.index', {
            url: '/',
            title: 'visites',
            templateUrl: 'visites/index.html',
            bodyClass: ''
        })

        .state('app.ticket-remise', {
            url: '/ticket-remise',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'ticketAndRemiseController as vm'
        })
        .state('app.ticket-remise.index', {
            url: '/',
            title: 'ticket et remise',
            templateUrl: 'ticket_and_remise/index.html',
            bodyClass: ''
        })




        .state('access', {
            abstract: true,
            url: '/access',
            template: '<div ui-view></div>',
            controller: 'authController as vm'
        })
        .state('access.login', {
            url: '/login',
            title: 'Se connecter',
            templateUrl: 'access/login.html',
            bodyClass: 'page-login page-header-fixed page-sidebar-fixed small-sidebar'
        })
        .state('access.password-oublie', {
            url: '/forgot-password',
            title: 'Mot de passse oublié',
            templateUrl: 'password-oublie.html',
            bodyClass: 'login-page'
        })
        .state('access.logout', {
            url: '/logout',
            controller: function ($state, $localStorage, $http, AppSettings,$window) {
                'ngInject';
                $localStorage.$reset();
                $http.get(AppSettings.apiUrl + 'logout').then((response) => { 
                    //$state.go('access.login', {}, { reload: true });
                    //$state.reload('access');
                }, ((err) => {
                    console.log(err);
                }));
                $state.go('access.login', {}, { reload: true });
                //$state.reload('access');
            }
        })

    $urlRouterProvider.otherwise('/access/login');

    //console.log($rootScopeProvider);

}

export default OnConfig;