function visitesController(DatatableLang, $compile, $scope,$translate, WS, $window, $uibModal) {
    'ngInject';
    const vm = this;
    vm.visites = [];
    vm.search = {};
    vm.search.date_debut =  moment().format('YYYY-MM-DD');
    vm.search.date_fin = moment().format('YYYY-MM-DD');


   
 


    vm.getHaversine = (latitude_client, longitude_client, latitude, longitude) => {
        const client = { latitude: parseFloat(latitude_client), longitude: parseFloat(longitude_client) }
        const visite = { latitude: parseFloat(latitude), longitude: parseFloat(longitude) }

        if(_.isNaN(client.latitude) || _.isNaN(client.latitude)){
            return 'PCI'
        }
        else if(_.isNaN(visite.latitude) || _.isNaN(visite.latitude)){
            return 'PVI'
        }else {
            var position = haversine(client, visite)
            if(position > 1000){
                position = position / 1000
                position =  position.toFixed(3) + ' Km';
            }else{
                position =  position.toFixed(3) + ' m';
  
            }
            return position;
        }
         //return distance en métre
    }

    vm.getVisites = function() {
        WS.getData('visites/getVisitesDashboard', vm.search)
            .then(function(response) {
                if (response.status == 200) {
                    vm.visites = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.showCurrentMap = (visite) =>{
        vm.selectmapsclients = angular.copy(vm.clients);
        vm.centerroutes = angular.copy(visite.latitude + ', ' + visite.longitude);
        vm.zoomRouting = 15;
        $('#container-maps').empty();
        $('#container-maps').html('<div style="width: 100%; height: 500px" id="mapContainerClients"></div>');

        var mymap = L.map('mapContainerClients').setView([visite.latitude, visite.longitude], vm.zoomRouting);


        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}', {
            foo: 'bar',
            attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
        })
            .addTo(mymap);


            /**
             * marker visite
             */
            var redMarker = L.ExtraMarkers.icon({
                icon: 'fa-binoculars',
                markerColor:  'blue',
                shape: 'circle', //'circle', 'square', 'star', or 'penta'
                prefix: 'fa'
            });

            var marker = L.marker([visite.latitude, visite.longitude], { icon: redMarker }).addTo(mymap);
            var info = $compile(`
                                 <div id="bodyContent" style="width=300px;">
                                      <p class="text-center">
                                        <a ui-sref="app.clients.details({id : ${visite.client_id} })" target="_blank">
                                            Modifier
                                        </a>
                                        <b> | </b>
                                        <a ui-sref="app.clients.historiques({code : ${visite.client_code}})" target="_blank">
                                          Historique
                                        </a>
                                      </p>
                                      <h3> Visite</h3></br>

                                      <b>Client</b> : ${visite.client}</a></br>
                                      <b>Code</b> : ${visite.client_code}</a></br>
                                      <b>Code a barre</b> : ${visite.code_a_barre}</a></br>
                                      <b>Route</b> : ${visite.routing_code}</br>
                                      <b>Mobile</b> : ${visite.mobile}</br>
                                      <b>Commercial</b> : ${visite.user}</br>
                                    </div>
                                `)($scope);
            var popup = L.popup({
                maxWidth: 600,
                minWidth: 200
            }).setContent(info[0])
            marker.bindPopup(popup);


            /**
             * marker Client
             */
            var redMarker = L.ExtraMarkers.icon({
                icon: 'fa-home',
                markerColor:  'red',
                shape: 'circle', //'circle', 'square', 'star', or 'penta'
                prefix: 'fa'
            });

            var marker = L.marker([visite.latitude_client, visite.longitude_client], { icon: redMarker }).addTo(mymap);
            var info = $compile(`
                                 <div id="bodyContent" style="width=300px;">
                                      <p class="text-center">
                                        <a ui-sref="app.clients.details({id : ${visite.client_id} })" target="_blank">
                                            Modifier
                                        </a>
                                        <b> | </b>
                                        <a ui-sref="app.clients.historiques({code : ${visite.client_code}})" target="_blank">
                                          Historique
                                        </a>
                                      </p>
                                      <h3> Position de Client</h3></br>
                                      <b>Client</b> : ${visite.client}</a></br>
                                      <b>Code</b> : ${visite.client_code}</a></br>
                                      <b>Code a barre</b> : ${visite.code_a_barre}</a></br>
                                      <b>Route</b> : ${visite.routing_code}</br>
                                      <b>Mobile</b> : ${visite.mobile}</br>
                                      <b>Commercial</b> : ${visite.user}</br>
                                    </div>
                                `)($scope);
            var popup = L.popup({
                maxWidth: 600,
                minWidth: 200
            }).setContent(info[0])
            marker.bindPopup(popup);

            setTimeout(function() {
                mymap.invalidateSize();
              }, 1000);

            console.log(visite.latitude_client, visite.latitude_client, visite.latitude, visite.longitude);
    }

    vm.selectUsers = function () {
        WS.get('users/selectUsers', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.affectationImageClient = function (obj, index) {
    
        vm.panel_change_image = $uibModal.open({
            animation: true,
            templateUrl: 'panel_change_image',
            size: 'lg',
            backdrop: true,
            controller: function ($scope, $translate) {
                $scope.obj = obj;
                console.log('$scope.obj.', $scope.obj);

                var data = {
                    img_visite_code : $scope.obj.visite_code, // image de client  : client_code and type = 'cover'
                    img_client_barre_code : $scope.obj.code_a_barre, // image de visite  : visite_code and type = 'visite'
                };
                //console.log('data', data);

                $scope.submitAffectationImageClient = function () {
                    vm.submitAffectationImageClient(data, index);
                };
            }
        });



        
    };

    vm.submitAffectationImageClient = (data, index) => {
        console.log(data);
        WS.post('images/affectationImageClient', data).then(
            function (response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succés'),
                        $translate.instant('Client modifié avec succès'),
                        'success'
                    ).then(function () {
                        vm.panel_change_image.close({});
                    }).catch(function () {
                        vm.panel_change_image.close({});
                    });

                    vm.visites[index].cover_img = data.img_to_update;
                    

                } else {
                    swal(
                        $translate.instant('Attention'),
                        $translate.instant('erreur'),
                        'warning'
                    );
                }
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            }
        );
    }

    vm.getImagesByCodeABarre = (code_a_barre) => {
        WS.get('clients/getImagesByCodeABarre/' + code_a_barre)
            .then(function (response) {
                if (response.data.length > 0) {
                    vm.imagesclient = response.data;
                    jQuery.noConflict();
                    $('#imagesClient').modal('show');
                    $scope.$apply();
                } else {
                    alert($translate.instant('Aucune image correspond a ce client'));
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    vm.deleteVisite = function (code) {
        //console.log(code);
        //console.log(user);
        swal({
            title: $translate.instant(`Suppression`),
            // text: $translate.instant('code bl') + ` : `,
            html: `${$translate.instant('Vos étes sur le point de supprimer cette visite')}
                    <h4 class="text-center">${code}</h4>`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui, supprimer!'),
            cancelButtonText: $translate.instant('Non, Annuler!'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            $uibModal.open({
                animation: true,
                templateUrl: 'VerificationOfPassword',
                size: 'md',
                backdrop: true,
                controller: function ($scope, $translate) {
                    $scope.message_password = $translate.instant('confirmer le mot de passe');
                    $scope.current_numero = code;

                    $scope.deleteVisite = function () {
                        if ($scope.set_password != '' && $scope.set_password != undefined) {
                            var data = {
                                numero: $scope.current_numero,
                                password: $scope.set_password
                            };
                            console.log(data);
                            WS.post('visites/deleteVisiteFromDash', data).then(
                                function (response) {
                                    if (response.data.success) {
                                        swal(
                                            $translate.instant('Succès'),
                                            $translate.instant('Supprimée'),
                                            'success'
                                        ).then(function () {
                                            $window.location.reload();
                                        }).catch(function () {
                                            $window.location.reload();
                                        });;
                                    } else {
                                        swal(
                                            $translate.instant('Attention'),
                                            $translate.instant(response.data.message),
                                            'warning'
                                        );
                                    }
                                },
                                function () {
                                    swal(
                                        $translate.instant('Attention'),
                                        $translate.instant('erreur de chargement des données'),
                                        'warning'
                                    );
                                }
                            );
                        } else {
                            swal(
                                $translate.instant('Attention'),
                                $translate.instant('Mot de passe Obligatoire'),
                                'warning'
                            );
                        }
                    }
                }
            });

        });

    };




    vm.getCategories = function () {
        WS.get('categoriescommerciale')
            .then(function (response) {
                if (response.status == 200) {

                    vm.categories = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


}
export default {
    name: 'visitesController',
    fn: visitesController
};