function objectifController(DatatableLang, $state, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.objectifs = {};
    vm.multi_objs = {};
    //vm.multi_objs.users = [];
    vm.find_by_month = moment().format("YYYY-MM");
    vm.multi_objs.month = moment().format("YYYY-MM");
    vm.get = function () {
        WS.get('objectifs/all', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.objectifs = response.data;
                    $scope.$apply();
                    $('.tableobj').DataTable({
                        "language": DatatableLang
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.initget = function () {
        WS.get('objectifs/all', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.objectifs = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getFilter = function (filter) {
        WS.get('objectifs/all/' + filter.date_debut + '/' + filter.date_fin, vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.objectifs = response.data;
                    $scope.$apply();
                    $('.tableobj').DataTable({
                        "language": DatatableLang
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getcommercial = function () {
        WS.getScache('users/selectComercial')
            .then(function (response) {
                if (response.status == 200) {
                    vm.commercials = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getMarques = function () {
        WS.get('marques')
            .then(function (response) {
                if (response.status == 200) {
                    vm.marques = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getgammes = function () {
        WS.get('gammes', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.gammes = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getCommercialByEquipement = function (code, liste, attribute) {
        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i][attribute] == code) {
                return liste[i];
            }

        }
    }

    vm.ajouter = function (objectif) {
        WS.post('objectifs', objectif)
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        'objectif ajoutée avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error', response.data.message, 'error');
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.update = function (objectif) {
        console.log(objectif);
        WS.put('objectifs/all/' + objectif.id, objectif)
            .then(function (response) {
                if (response.status == 200) {
                    swal(
                        'Succès',
                        'objectif modifiée avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.delete = function (id) {
        swal({
            title: 'Supprimer',
            text: "Voulez vous vraiment supprimer cette objectif!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.delete('objectifs/' + id).then(
                function () {
                    swal(
                        'Supprimée!',
                        'objectif Supprimée avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                },
                function () {
                    swal(
                        'Attention!',
                        'Vous ne pouvez pas supprimer cette objectif',
                        'warning'
                    );
                }
            );
        });
    }

    /**
     * All Graph for objectif
     */

    vm.objectifGraph = function () {
        WS.getScache('objectifs/getObjGraph').then(function (response) {
            console.log(response.data);
            vm.objectifsgraph = response.data;
            $scope.$apply();
            $('.tablegetObjGraph').DataTable({
                "language": DatatableLang
            });
        })
            .then(null, function (error) {
                console.log(error);
            });
    };
    vm.initobjectifGraph = function () {
        WS.getScache('objectifs/getObjGraph').then(function (response) {
            vm.objectifsgraph = response.data;
            $scope.$apply();
        })
            .then(null, function (error) {
                console.log(error);
            });
    };
    vm.objectifGraphFilter = function (filter) {
        WS.getScache('objectifs/getObjGraph/' + filter.date_debut + '/' + filter.date_fin).then(function (response) {
            vm.objectifsgraph = response.data;
            $scope.$apply();
        })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.objectifGraphComm = function () {
        WS.get('objectifs/getObjGraphComm').then(function (response) {

            if (response.status == 200) {
                vm.objectifsgraphcomm = response.data;
                $scope.$apply();
                $('.tablegetObjGraph').DataTable({
                    "language": DatatableLang
                });
            } else { }
        })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.initobjectifGraphComm = function () {
        WS.get('objectifs/getObjGraphComm').then(function (response) {

            if (response.status == 200) {
                vm.objectifsgraphcomm = response.data;
                $scope.$apply();
            } else { }
        })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.objectifGraphCommFilter = function (filter) {
        WS.get('objectifs/getObjGraphComm/' + filter.date_debut + '/' + filter.date_fin).then(function (response) {

            if (response.status == 200) {
                vm.objectifsgraphcomm = response.data;
                $scope.$apply();
            } else { }
        })
            .then(null, function (error) {
                console.log(error);
            });
    }


    /*vm.obj = 80;
    var chart = AmCharts.makeChart("objectifGraph", {
                            "theme": "light",
                            "type": "gauge",
                            "fontSize": 9,
                            "axes": [{
                              "topTextFontSize": 20,
                              "topTextYOffset": 70,
                              "fontSize": 7,
                              "axisColor": "#31d6ea",
                              "axisThickness": 1,
                              "endValue": 100,
                              "gridInside": true,
                              "inside": true,
                              "radius": "50%",
                              "valueInterval": 10,
                              "tickColor": "#67b7dc",
                              "startAngle": -90,
                              "endAngle": 90,
                              "unit": "%",
                              "bandOutlineAlpha": 0,
                              "bands": [{
                                "color": "#0080ff",
                                "endValue": 100,
                                "innerRadius": "105%",
                                "radius": "170%",
                                "gradientRatio": [0.5, 0, -0.5],
                                "startValue": 0
                              }, {
                                "color": "#3cd3a3",
                                "endValue": 0,
                                "innerRadius": "105%",
                                "radius": "170%",
                                "gradientRatio": [0.5, 0, -0.5],
                                "startValue": 0
                              }]
                            }],
                            "arrows": [{
                              "alpha": 1,
                              "innerRadius": "35%",
                              "nailRadius": 0,
                              "radius": "170%"
                            }]
                          });

                          setInterval(randomValue, 1000);

                          // set random value
                          function randomValue() {
                            var value = vm.obj;
                            chart.arrows[0].setValue(value);
                            chart.axes[0].setTopText(value + " %");
                            // adjust darker band to new value
                            chart.axes[0].bands[1].setEndValue(value);
                          }
    */

    vm.listObjectifsVisites = function (filter) {
        WS.get('objectifs/listObjectifsVisites')
            .then(function (response) {
                if (response.status == 200) {
                    vm.listobjvisites = response.data;
                    $scope.$apply();
                    $('.tableobjvisite').DataTable({
                        "language": DatatableLang
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.saveObjectifsVisites = function () {
        WS.post('objectifs/saveObjectifsVisites', vm.addobjvisite)
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        'objectif ajoutée avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error', response.data.message, 'error');
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.updateObjectifsVisites = function () {
        //console.log(objectif);
        WS.put('objectifs/updateObjectifsVisites', vm.modifObjectif)
            .then(function (response) {
                if (response.status == 200) {
                    swal(
                        'Succès',
                        'objectif modifiée avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.deleteObjectifsVisites = function (id) {
        swal({
            title: 'Supprimer',
            text: "Voulez vous vraiment supprimer cette objectif!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.delete('objectifs/deleteObjectifsVisites/' + id).then(
                function () {
                    swal(
                        'Supprimée!',
                        'objectif Supprimée avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                },
                function () {
                    swal(
                        'Attention!',
                        'Vous ne pouvez pas supprimer cette objectif',
                        'warning'
                    );
                }
            );
        });
    }



    /**
     * 
     */
    vm.initDatepicker = function () {
        $(".datepicker-dashb").datepicker({
            autoClose: true,
            onSelect: function (date) {
                vm.multi_objs.month = date;
                $scope.$apply();
                //console.log(vm.month);
            }
        });

        $(".datepicker-dashb-search").datepicker({
            autoClose: true,
            onSelect: function (date) {
                vm.find_by_month = date;
                $scope.$apply();
                //console.log(vm.month);
            }
        });

        // $(".week-picker").weekpicker({
        //     beforeShowDay: $.datepicker.noWeekends,
        //     autoClose: true,
        //     onSelect: function (date) {
        //         vm.multi_objs.week = date;
        //         $scope.$apply();
        //         //console.log(vm.month);
        //     }
        // });
        // $(document).ready(function () {
        //     $('.week-picker').weekpicker();
        // })


    };

    vm.getFamilles = function () {
        WS.get('familles')
            .then(function (response) {
                vm.familles = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getSousfamilles = function () {
        WS.get('sousfamilles')
            .then(function (response) {
                vm.sousfamilles = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getGroupes = function () {
        WS.get('groupes/select')
            .then(function (response) {
                vm.groupes = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };
    vm.selectProduits = function () {
        WS.get('produits/selectProduit')
            .then(function (response) {
                if (response.status == 200) {
                    vm.produits = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };
    vm.selectClientsWithCode = function () {
        WS.get('clients/selectClientsWithCode')
            .then(function (response) {
                if (response.status == 200) {
                    vm.clients = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getListOfOblectifs2 = function () {
        WS.getData('objectifs/getListOfOblectifs2', { obj_month : vm.find_by_month})
            .then(function (response) {
                if (response.status == 200) {
                    vm.list_objectifs_2 = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };
    
    

    vm.gererLesObjectifs = function () {
        swal({
            title: 'Enregistrer',
            text: "Voulez vous vraiment enregistrer les objectifs ? ",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            console.log(vm.multi_objs);

            WS.post('objectifs/storeMultipleObjectifs', vm.multi_objs).then(function (response) {
                if (response.data.success == true) {
                    swal({
                        title: 'Succé',
                        text: "Les objectifs sont enregistrés avec succès",
                        type: 'success',
                    });
                    //$window.location.reload();
                    vm.multi_objs.month = moment().format("YYYY-MM");
                    vm.multi_objs.week = '';
                    _.each(vm.commercials, (element, index) => {
                        vm.multi_objs.users[element.code] = {};
                    })
                    $scope.$apply();
                } else { }
            })
                .then(null, function (error) {
                    console.log(error);
                });
        });
    };



    vm.updateObjectifs2 = function(){
        console.log(vm.update_objectif2);
        WS.post('objectifs/updateObjectifs2', vm.update_objectif2)
        .then(function (response) {
            if (response.status == 200) {
                _.each(vm.list_objectifs_2, (element,index) =>{
                    if(element.id == response.data.id){
                        vm.list_objectifs_2[index] = response.data; 
                    }
                })
                swal('succé', 'Objectif modifié avec succé', 'success');
                $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });;
    };


}
export default {
    name: 'objectifController',
    fn: objectifController
};